"use client"
import { useEffect, useRef, Fragment, useState } from "react"
import { RudderAnalytics } from "@rudderstack/analytics-js"
import { Dialog, Transition } from "@headlessui/react"

function SignUpDialog() {
  const [isOpen, setIsOpen] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const emailInputRef = useRef<HTMLInputElement>(null)

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    if (!isSubmitting) {
      setIsOpen(false)
    }
  }

  const rudderAnalytics = useRef<RudderAnalytics | null>(null)

  useEffect(() => {
    const writeKey = process.env.NEXT_PUBLIC_RUDDER_WRITE_KEY
    const dataPlaneUrl = process.env.NEXT_PUBLIC_RUDDER_DATAPLANE_URL
    if (writeKey && dataPlaneUrl) {
      rudderAnalytics.current = new RudderAnalytics()
      rudderAnalytics.current.load(writeKey, dataPlaneUrl)
      rudderAnalytics.current.ready(() => {
        console.log("RudderStack is ready")
      })
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        if (emailInputRef.current) {
          emailInputRef.current.focus()
        }
      }, 300) // Adjust this timeout as needed to match the duration of your transition
    }
  }, [isOpen])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsSubmitting(true)

    const email = (event.target as HTMLFormElement).email.value

    if (rudderAnalytics.current) {
      rudderAnalytics.current.track("Email Submitted", {
        email: email,
        source: "decker.com/signupdialog",
        waitlist_category: "beta0"
      })
    } else {
      console.error("RudderStack environment variables are missing.")
    }

    setIsSubmitted(true)
    setIsOpen(false)
    setIsSubmitting(false)
  }

  return (
    <div className="flex items-center justify-center rounded bg-transparent">
      {!isSubmitted ? (
        <button
          type="button"
          disabled={isSubmitting}
          onClick={openModal}
          className="my-2 rounded bg-blue-600 px-4 py-2 text-white"
        >
          Join the waitlist
        </button>
      ) : (
        <div className="md:text-md my-2 rounded bg-gray-100 px-2 py-1 text-sm text-gray-400 md:px-4 md:py-2">
          You're on the waitlist
        </div>
      )}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          {!isSubmitted ? (
            <div className="min-h-screen px-4 text-center">
              <Transition appear show={isOpen} as="div">
                <div className="fixed inset-0 z-40 bg-black/50" />

                <span className="inline-block h-screen align-middle" aria-hidden="true">
                  &#8203;
                </span>
                <Transition.Child
                  as="div"
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                  className="fixed inset-0 z-50 flex items-center justify-center"
                >
                  <div className="my-8 inline-block w-full max-w-md overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Join the waitlist!
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        We'll keep you updated on our progress and let you know when we
                        launch...
                      </p>
                    </div>
                    <form
                      className="mt-5 sm:flex sm:items-center"
                      onSubmit={handleSubmit}
                    >
                      <div className="w-full sm:max-w-xs">
                        <label htmlFor="email" className="sr-only">
                          Email
                        </label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          required
                          className="block w-full rounded-md border-0 px-2.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="Your email"
                          ref={emailInputRef}
                        />
                      </div>
                      <button
                        type="submit"
                        className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:ml-3 sm:mt-0 sm:w-auto"
                      >
                        {isSubmitting ? "Submitting..." : "Submit"}
                      </button>
                    </form>
                  </div>
                </Transition.Child>
              </Transition>
            </div>
          ) : (
            <p>Thanks!</p>
          )}
        </Dialog>
      </Transition>
    </div>
  )
}

export default SignUpDialog

import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/vercel/path0/apps/webapp/src/app/components/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PrimaryFeatures"] */ "/vercel/path0/apps/webapp/src/app/components/PrimaryFeatures.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SecondaryFeatures"] */ "/vercel/path0/apps/webapp/src/app/components/SecondaryFeatures.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/webapp/src/images/background-faqs.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/webapp/src/images/background-features.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/webapp/src/images/logos/laravel.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/webapp/src/images/logos/mirage.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/webapp/src/images/logos/statamic.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/webapp/src/images/logos/statickit.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/webapp/src/images/logos/transistor.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/webapp/src/images/logos/tuple.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/webapp/src/images/screenshots/all-in-one.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
